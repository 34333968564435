import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Content, { HTMLContent } from "../../components/Content"

const MetiersPage = ({ data }) => {
  const { edges } = data.allMarkdownRemark
  const PostContent = HTMLContent || Content
  return (
    <Layout langKey={"en"}>
      <SEO
        title="Our IT engineering activities"
        description="We carry out your tailor-made IT projects in the entirety of the specifications up to delivery and maintenance"
        keywords={[`Audit`, `Consulting`, `Needs Analysis`, `Design`, `Development`, `Prototyping`, `Maintenance`, `Integration`, `Software Testing`, `Training`]}
        jsonLd={[
          {
            "@context": "https://schema.org",
            "@type": "BreadcrumbList",
            itemListElement: [
              {
                "@type": "ListItem",
                position: 1,
                name: "Activities",
                item: "https://www.aprogsys.com/en/metiers/",
              },
            ],
          },
        ]}
      />
      <section className="hero metiers-banner" style={{overflow: 'hidden'}}>
        <div className="container is-fluid hero-body" style={{position:'relative'}}>
          <div className="columns">
              <div className="column is-7">
                <div className="hero-title">
                  <div style={{position:'relative',zIndex: 2}}>
                    <h1 className="is-size-3-touch" ><span>Our activities</span></h1>
                    <p className="subtitle is-size-4-touch is-size-3-tablet is-size-2-desktop" style={{ marginTop: '1rem' }}><span>The realization of a <strong>digital transformation</strong> project is a challenge.</span></p>
                    <p className="subtitle is-size-4-touch is-size-3-tablet is-size-2-desktop"><span>Our team guides you and accompanies you towards its realization.</span></p>
                  </div>
                  <div className="is-hidden-mobile">
                    <svg style={{width:'80%',height: 400, maxWidth:1000,position:'absolute',bottom:-30, right:-60, zIndex: 1}}>
                      <use xlinkHref="/img/metiers-sprite.svg#icon-montagne" />
                    </svg>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </section>
      <section className="metiers-details">
        <div className="container">
          <div style={{paddingBottom: '50px'}}>
            <h2 className="is-size-4-touch has-text-primary">{edges[0].node.frontmatter.title}</h2>
            <PostContent content={edges[0].node.html} className={"has-text-justified metiers-details-text"} />
          </div>
          <div className="content metiers-details-structure">
            <div className="is-hidden-touch is-hidden-desktop-only complFond1"></div>
            <div className="is-hidden-touch is-hidden-desktop-only complFond2"></div>    
            <div className="metier1 has-text-weight-bold is-hidden-touch is-hidden-desktop-only">
              <p className="is-size-2-desktop">1. Needs <br/>analysis</p>
            </div>
            <div className="metier1 has-text-weight-bold is-hidden-widescreen">
              <h2 className="is-size-4-touch">1. Needs analysis</h2>
            </div>

            <div className="metier1-ssm1">
              <svg style={{height:92}}>
                <use xlinkHref="/img/metiers-sprite.svg#icon-audit" />
              </svg>
              <h3 className="metier1-details is-size-5-touch is-marginless">Audit and consulting</h3> 
              <p className="is-marginless"><strong>The complete construction of your project</strong></p>
              <ul>
                <li>Analysis of the main needs</li>
                <li>Situation assessment</li>
                <li>Study of the possibilities (according to your constraints)</li>
                <li>Explanation of possible solutions and advice</li>
              </ul>
            </div>
            <div className="metier1-ssm2">
              <svg style={{height:92}}>
                <use xlinkHref="/img/metiers-sprite.svg#icon-gestion" />
              </svg>
              <h3 className="metier1-details is-marginless">Project management</h3> 
              <p className="is-marginless"><strong>Assignment of a privileged contact person (project manager)</strong></p>
              <ul>
                <li>Overall project management</li>
                <li>Drafting of the functional specifications</li>
                <li>Financial proposal</li>
                <li>Commitment to deadlines and resolution of unforeseen events</li>
              </ul>
            </div>

            <div className="metier2 has-text-right-desktop has-text-weight-bold is-hidden-touch is-hidden-desktop-only">
              <p className="is-size-2-desktop">2. Design <br/>and develop</p>
            </div>
            <div className="metier2 has-text-weight-bold is-hidden-widescreen">
              <h2 className="is-size-4-touch">2. Design and develop</h2>
            </div>

            <div className="metier2-ssm1">
              <svg style={{height:92}}>
                <use xlinkHref="/img/metiers-sprite.svg#icon-dev" />
              </svg>
              <h3 className="metier2-details is-marginless">Software development</h3> 
              <p className="is-marginless"><strong>Develop, design and edit: the watchwords that our technicians master to perfection</strong></p>
              <ul>
                <li>Development under all types of environments</li>
                <li>Various programming languages</li>
                <li>Website design and development</li>
              </ul>
            </div>
            
            <div className="metier2-ssm2">
              <svg style={{height:92}}>
                <use xlinkHref="/img/metiers-sprite.svg#icon-proto" />
              </svg>
              <h3 className="metier2-details is-marginless">Prototyping and electronics</h3> 
              <p className="is-marginless"><strong>Elaboration of electronic cards<br/> according to your needs</strong></p>
            </div>

            <div className="metier3 is-hidden-touch is-hidden-desktop-only has-text-weight-bold">
              <p className="is-size-2-desktop">3. Delivery and<br />maintenance of solutions</p>
            </div>
            <div className="metier3 has-text-weight-bold is-hidden-widescreen">
              <h2 className="is-size-4-touch">3. Delivery and maintenance of solutions</h2>
            </div>

            <div className="metier3-ssm1">
              <svg style={{height:90}}>
                <use xlinkHref="/img/metiers-sprite.svg#icon-tests" />
              </svg>
              <h3 className="metier3-details is-marginless">Maintenance, integration and testing</h3> 
              <p className="is-marginless"><strong>Personalized assistance</strong><br/>
                We will be at your side to advise you, guide you and implement your hardware and software solution. 
                Once the solution is operational, your maintenance package (after consultation) will cover all malfunctions of your software
              </p>
            </div>

            <div className="metier4 has-text-right-desktop is-hidden-touch is-hidden-desktop-only has-text-weight-bold">
              <p className="is-size-2-desktop">4. Ensure the evolution<br />of solutions and training</p>
            </div>
            <div className="metier4 has-text-weight-bold is-hidden-widescreen">
              <h2 className="is-size-4-touch">4. Ensure the evolution of solutions and training</h2>
            </div>
            
            <div className="metier4-ssm1">
              <svg style={{height:92}}>
                <use xlinkHref="/img/metiers-sprite.svg#icon-formation" />
              </svg>
              <h3 className="metier4-details is-marginless">Training and follow-up</h3>
              <p className="is-marginless"><strong>A service that is attentive to your needs over the long term</strong><br/>
                Our experts offer you adapted training courses so that you can make the most of the functionalities of your software.
                This is part of Aprogsys' customer follow-up program, to be as close as possible to your problems throughout the product's operating life.
              </p>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

MetiersPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default MetiersPage

export const MetiersPageQuery = graphql`
query MetiersEnQuery {
  allMarkdownRemark(
    filter: { frontmatter: { templateKey: { eq: "metiers" }, langKey: { eq: "en" } } }
  ) {
    totalCount
    edges {
      node {
        id
        html
        frontmatter {
          title
        }
      }
    }
  }
}
`